import React, { useState } from "react"
import 'react-bootstrap-typeahead/css/Typeahead.css';
import '../assets/scss/components.scss';
import { Typeahead } from "react-bootstrap-typeahead";
import { ApiClient } from "../services/apiClient";





export const ReactBestInPlace: React.FC<{ values: any }> = ({ values }) => {
    const [value, setValue] = useState(JSON.parse(values.value))
    const [editing, setEditing] = useState(false);

    const updateRecord = () => {
        const realValue = value.map((f: any) => f.name);

        ApiClient.contacts.update({ function: realValue, id: values.id }).then(res => {
            setEditing(false)
        })

    }



    const options = JSON.parse(values.options);


    if (editing) {
        return (
            <Typeahead
                id='react-best-in-place-function'
                autoFocus
                labelKey="name"
                selected={value}
                onChange={(v) => setValue(v)}
                onBlur={() => updateRecord()}
                multiple
                options={options}
            />



        )
    }
    return (

        <p onClick={() => setEditing(true)} style={{ width: '100%', display: 'inline-block', minWidth: 70, minHeight: 10, cursor: 'pointer' }}>


            {value.map((x: any, index: number) => <span key={index} style={{ fontWeight: x.rank === 1 ? "bold" : "normal", color: x.rank === 1 ? "green" : "" }}>{x.name} {index < value.length - 1 && ","} </span>)}
        </p>
    )
}