import React from 'react'
import { render } from 'react-dom';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { ReactBestInPlace } from '../src/components/ReactBestInPlace';
import { AddTags } from './addTags';

const elements = document.getElementsByClassName('react-best-in-place') as HTMLCollectionOf<HTMLElement>;
for (let element of elements) {
    const values = element.dataset

    render(<ReactBestInPlace values={values} />, element)
}


